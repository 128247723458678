@import "../../theme_variables";

/* Block Viewed Products */
#viewed-products_block_left {
	li.last_item {
		padding-bottom:0;
		margin-bottom:0;
		border-bottom:none;	
	}
}
